import React from "react";
import styled from "styled-components";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { COLORS } from "../GlobalStyles";

const SocialMedia = () => {
  return (
    <Wrapper>
      <a
        href="https://www.linkedin.com/in/francescofakhoury/"
        target="_blank"
        rel="author"
      >
        <Icon>
          <FaLinkedin />
        </Icon>
      </a>
      <a
        href="https://github.com/FrancescoFakhoury"
        target="_blank"
        rel="author"
      >
        <Icon>
          <FaGithub />
        </Icon>
      </a>
      <a href="mailto:francesco.fakhoury@hotmail.com" target="_blank" rel="author">
        <Icon>
          <GrMail />
        </Icon>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-right: 30px;
  a {
    div {
      color: ${COLORS.lightest};
    }
    &:hover {
      div {
        color: ${COLORS.light};
      }
    }
  }
`;

const Icon = styled.div`
  display: flex;
  color: black;
  margin: 0 15px;
  padding: 5px;
  border-radius: 50%;
  font-size: 2rem;
  transition: 0.4s ease-in-out;
`;

export default SocialMedia;
