import React from "react";
import { FaRegCopyright } from "react-icons/fa";
import styled from "styled-components";
import { COLORS } from "../GlobalStyles";
const Footer = () => {
  return (
    <Wrapper>
      <CopyrightWrapper>
        <FaRegCopyright size="1rem" color="white" />
        <p>
          <span>2021-2021</span> Francesco Fakhoury
        </p>
      </CopyrightWrapper>
      <Copyright>
          <p>Icons made by</p>
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>
          <p>from</p>
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
      </Copyright>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 100px 100px 0px;
  p {
    font-size: 18px;
    font-family: Var(--font-family);
    color: ${COLORS.lightest};
  }
  span {
    font-weight: bold;
    color: ${COLORS.lightest};
  }
  @media (max-width: 768px) {
    margin: 0px 20px;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
`;
const Copyright = styled.div`
display: flex;
align-items: baseline;
a {
    text-decoration: none;
    color: ${COLORS.light};
    padding: 0px 10px;
}
p {
    color: ${COLORS.lightest};
}
@media (max-width: 768px) {
}
`;

export default Footer;
