import React, { useEffect, useState } from "react";
import styled from "styled-components";
import background from "../assets/blue-texture.jpg";

import { COLORS, fadeIn} from "../GlobalStyles";
import education from "../assets/education.png";
import BoxImage from "./BoxImage";

const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isTrue, setIsTrue] = useState(false);
  const [isFirstTrue, setIsFirstTrue] = useState(false);
  const [isSecondTrue, setIsSecondTrue] = useState(false);
  const [question, setQuestion] = useState(false);

  const handleClick = () => {
    setIsTrue(!isTrue);
    setQuestion(true);
  };

  const handleFirstClick = () => {
    setQuestion(true);
    setIsFirstTrue(!isFirstTrue);
  };

  const handleSecondClick = () => {
    setQuestion(true);
    setIsSecondTrue(!isSecondTrue);
  };

  console.log(question, "question");
  console.log(isTrue, "isTrue");
  return (
    <>
      <Background></Background>
      <Wrapper>
        <Me>
          {/* <img src={frank1} alt="" /> */}
          <BoxImage />
          <Info>
            <h1>Hi There</h1>
            <p>
              I'm Francesco Fakhoury. I am a Full-Stack Web Developer with an
              absolute passion for coding. I speak both English and French as I
              currently reside in Montreal, Canada,
            </p>
            <p>
              Aside from creating thoughtful applications, I enjoy playing
              videogames, going to the gym to maintain a healthy lifestyle and
              watching the Montreal Canadiens as I am a big fan. I consider
              myself to be an absolute extroverted human being who loves
              socializing and finding new opportunities to make new friends and
              grow my inner social circle.
            </p>
          </Info>
        </Me>
        <Education>
          <section>
            <h1>Education</h1>
            <h2>Concordia University</h2>
            <h3>Diploma, Full-Stack Web Development</h3>
            <p>
              This Bootcamp consisted of an intense 12-week curriculum that
              taught me the fundamentals of programming with modern languages
              and allowed me to construct MERN applications. Having done this
              bootcamp as a total beginner, I can easily say that this was the
              most challenging thing I have ever experienced.
            </p>
          </section>
          <img src={education} alt=""></img>
        </Education>
        <PersonalInfo>
          <h1>Two truths One lie</h1>
          <h3>Pick the lie and see if you know me well !!!</h3>
          <section>
            {isFirstTrue === true ? (
              <FalseButton onClick={handleFirstClick}>
                FALSE! This is true.
              </FalseButton>
            ) : (
              <Button onClick={handleFirstClick}>
                I have gone bungee jumping at the highest place in all of
                Central America
              </Button>
            )}
            {isSecondTrue === true ? (
              <FalseButton onClick={handleSecondClick}>
                FALSE! This is true.
              </FalseButton>
            ) : (
              <Button onClick={handleSecondClick}>
                I play a round of golf every single week whenever I can
              </Button>
            )}
            {isTrue === false ? (
              <Button onClick={handleClick}>My favourite color is blue</Button>
            ) : (
              <CorrectButton onClick={handleClick}>
                Correct! This is the lie.
              </CorrectButton>
            )}
          </section>
          {/* {question === false ? (
            ""
          ) : (
            <>
              {isTrue === false ? (
                <p>FALSE! This is true.</p>
              ) : (
                <p>CORRECT! This is the lie.</p>
              )}
            </>
          )} */}
        </PersonalInfo>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 130px;
`;

const Me = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25%;
  padding: 75px 0 0 0;
  img {
    height: 500px;
    width: 400px;
    margin: 30px;
    border-radius: 50%;
    animation: 3s ease-in ${fadeIn};
  }
  p {
    color: ${COLORS.gray};
    animation: 3s ease-in ${fadeIn};
  }
  h1 {
    color: ${COLORS.light};
    font-size: 70px;
    animation: 3s ease-in ${fadeIn};
  }
`;

const Education = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 100px;
  margin-left: 30%;

  h1 {
    font-size: 70px;
    color: ${COLORS.light};
    animation: 5s ease-in ${fadeIn};
  }
  h2 {
    color: ${COLORS.gray};
    padding: 15px 0px 0px 0px;
    animation: 5s ease-in ${fadeIn};
    font-size: 30px;
  }
  h3 {
    color: ${COLORS.gray};
    font-style: italic;
    opacity: 0.8;
    animation: 5s ease-in ${fadeIn};
    font-size: 30px;
  }
  p {
    width: 600px;
    text-align: center;
    color: ${COLORS.gray};
    font-size: 25px;
    padding: 15px 0px 0px 0px;
    animation: 5s ease-in ${fadeIn};
  }
  img {
    animation: 5s ease-in ${fadeIn};
  }
`;

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-size: 70px;
    color: ${COLORS.light};
    animation: 5s ease-in ${fadeIn};
  }
  h3 {
    color: ${COLORS.lightest};
    font-style: italic;
    opacity: 0.8;
    animation: 5s ease-in ${fadeIn};
    font-size: 20px;
    padding: 50px 0px 0px 0px;
  }
  section {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 50px 0px 0px 0px;
    min-width: 900px;
  }
  p {
    text-align: center;
    color: white;
    font-size: 20px;
  }
`;

const Button = styled.button`
  color: ${COLORS.darkest};
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;

  &:hover {
    transform: scale(1.1);
  }
`;

const CorrectButton = styled.button`
  color: ${COLORS.darkest};
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  background-color: green;
  &:hover {
    transform: scale(1.1);
  }
`;

const FalseButton = styled.button`
  color: ${COLORS.darkest};
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  background-color: red;
  &:hover {
    transform: scale(1.1);
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  p {
    margin: 10px 0px;
    font-size: 25px;
  }
`;

const Background = styled.div`
  background: url(${background}) center center / cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
`;



export default AboutMe;
