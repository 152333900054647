import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../GlobalStyles";
import SocialMedia from "./SocialMedia";
import { FaHome } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineLaptop } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";

const Header = () => {
  return (
    <Wrapper>
      <Nav>
        <Icon>
          <BiMenu />
        </Icon>
        <ul>
          <li>
            <FaHome color="white" size="25px" />
            <Link to="/home">HOME</Link>
          </li>
          <li>
            <BsFillPersonFill color="white" size="25px" />
            <Link to="/about">ABOUT ME</Link>
          </li>
          <li>
            <AiOutlineLaptop color="white" size="25px" />
            <Link to="/projects">MY PROJECTS</Link>
          </li>
        </ul>
      </Nav>
      <SocialMedia />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: fixed;
  padding: 20px 20px;
  backdrop-filter: blur(8px);
  z-index: 1;
  @media (max-width: 950px) {
    justify-content: space-between;
    padding: 20px 20px;
  }
`;

const Nav = styled.nav`
  ul {
    display: flex;
  }

  li {
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 950px) {
    ul {
      /* background: ${COLORS.lightest}; */
      backdrop-filter: blur(8px);
      /* box-shadow: rgb(0 0 0 / 15%) 0px 4px 5px 0px; */
      flex-direction: column;
      position: fixed;
      z-index: 1;
      margin-top: 20px;
      width: 100%;
      li {
        opacity: 0;
        height: 0;
        width: 100vw;
        display: flex;
        align-items: center;
        transition: 0.3s ease-in-out;
        transition-delay: 0.3s;
        padding: 0px;
      }
    }
    &:hover {
      li {
        opacity: 1;
        height: 60px;
      }
    }
  }
`;

const Link = styled(NavLink)`
  font-size: 25px;
  color: white;
  border-radius: 6px;
  margin: 0 8px;
  padding: 0 10px;

  &:hover,
  &:focus {
    color: ${COLORS.light};
  }
  &.active {
    color: ${COLORS.medium};
    text-decoration: underline;
  }
  @media (max-width: 950px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 0;
    color: ${COLORS.lightest};
  }
`;

const Icon = styled.div`
  @media (max-width: 950px) {
    color: ${COLORS.lightest};
    font-size: 2rem;
    transition: 0.2s ease-in-out;
    text-align: center;
    &:hover,
    &:focus {
      color: ${COLORS.medium};
    }
  }
  @media (min-width: 950px) {
    display: none;
  }
`;

export default Header;
