import React from "react";
import styled from "styled-components";
import {boxRotate} from "../GlobalStyles";
import frank1 from "../assets/frank-linkedin-closeup.jpg";
import frank2 from "../assets/frank-2.jpg";
import frank3 from "../assets/frank-3.jpg";
import frank4 from "../assets/frank-4.jpg";
import frank5 from "../assets/frank-5.jpg";
import frank6 from "../assets/frank-6.jpg";

const BoxImage = () => {
  return (
    <BoxWrapper>
      <BoxArea>
        <Box1></Box1>
        <Box2></Box2>
        <Box3></Box3>
        <Box4></Box4>
        <Box5></Box5>
        <Box6></Box6>
      </BoxArea>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  width: 400px;
  height: 400px;
  margin: 15% auto;
`;

const BoxArea = styled.div`
  animation: ${boxRotate} 15s ease-in-out infinite;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  div {
    position: absolute;
    width: 400px;
    height: 400px;
  }
`;

const Box1 = styled.div`
  background-image: url(${frank1});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: translateZ(200px);
`;

const Box2 = styled.div`
  background-image: url(${frank2});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: rotateY(90deg) translateZ(200px);
`;

const Box3 = styled.div`
  background-image: url(${frank3});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: rotateY(90deg) rotateX(90deg) translateZ(200px);
`;
const Box4 = styled.div`
  background-image: url(${frank4});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: rotateY(180deg) rotateZ(90deg) translateZ(200px);
`;

const Box5 = styled.div`
  background-image: url(${frank5});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(200px);
`;

const Box6 = styled.div`
  background-image: url(${frank6});
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  transform: rotateX(-90deg) translateZ(200px);
`;

export default BoxImage;
