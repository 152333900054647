import React from "react";
import styled from "styled-components";
import { COLORS, rotate } from "../GlobalStyles";
import background from "../assets/blue-texture.jpg";

const Homepage = () => {
  return (
    <>
      <Background></Background>
      <Wrapper>
        <ContentWrapper>
          <Content>
            <Name>Francesco Fakhoury</Name>
            <Career>
              <h1>Full-Stack</h1>
              <h1>Web Developer</h1>
            </Career>
            <Skills>
              <p>html / css / javascript</p>
              <p>mongodb / express / react / redux / node</p>
              <p>gamer, coffee lover, sports fan, gym freak</p>
            </Skills>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

const Background = styled.div`
  background: url(${background}) center center / cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: fit-content;
`;

const Career = styled.div`
  margin-bottom: 50px;
  h1 {
    width: fit-content;
    font-size: 8rem;
    color: #ccc;
    text-align: center;
    @media (max-width: 780px) {
      font-size: 5rem;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.h1`
  text-align: center;
  width: fit-content;
  font-size: 3.5rem;
  color: ${COLORS.light};
  padding-bottom: 80px;
  margin-top: 200px;
  @media (max-width: 780px) {
    font-size: 3rem;
    margin: 0px;
  }
`;

const Skills = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 50px 0;
  color: #fff;
  width: 100%;
  p {
    font-size: 2rem;
    position: absolute;
    top: 0;
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
    animation: 12s ease-in-out infinite ${rotate};
    color: ${COLORS.gray};
  }
  p:nth-child(2) {
    animation-delay: 4s;
  }
  p:nth-child(3) {
    animation-delay: 8s;
  }
`;

export default Homepage;
