import React, { useEffect } from "react";
import styled from "styled-components";
import background from "../assets/blue-texture.jpg";
import benchmates from "../assets/BenchMates.PNG";
import { COLORS, fadeIn } from "../GlobalStyles";
import hodlnews from "../assets/hodl-news.PNG";
import ecom from "../assets/ecom.PNG";

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Background></Background>
      <Wrapper>
        <BenchMates>
          <img src={benchmates} alt="" />
          <Info>
            <p>01</p>
            <h1>BenchMates</h1>
            <p>
              A MERN stack Fantasy Hockey Application where you can battle your
              friends and see who's the ultimate Fantasy God.{" "}
            </p>

            <a
              href="https://github.com/FrancescoFakhoury/nhl-fantasy-app"
              target="_blank"
              rel="author"
            >
              <span>View Project Repo</span>
            </a>
          </Info>
        </BenchMates>
        <HodlNews>
          <img src={hodlnews} alt="" />
          <Info>
            <p>02</p>
            <h1>Hodl News</h1>
            <p>
              A Cryptocurrency News Aggregator where the top 20 articles of the
              day are listed from various publishers.
            </p>

            <a
              href="https://github.com/FrancescoFakhoury/News-Aggregator"
              target="_blank"
              rel="author"
            >
              <span>View Project Repo</span>
            </a>
          </Info>
        </HodlNews>
        <Ecom>
          <img src={ecom} alt="" />
          <Info>
            <p>03</p>
            <h1>Taco's Dream Team Store</h1>
            <p>
              an E-Commerce platform that was created as a group project for the
              bootcamp I participated in. I acted as the Team-Lead.
            </p>

            <a
              href="https://github.com/FrancescoFakhoury/group-project-ecom"
              target="_blank"
              rel="author"
            >
              <span>View Project Repo</span>
            </a>
          </Info>
        </Ecom>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const BenchMates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  padding: 260px 0 0 0;
  img {
    border-radius: 10px;
    border: 3px solid white;
    width: 650px;
    height: 400px;
  }
  p {
    color: ${COLORS.gray};
    animation: 3s ease-in ${fadeIn};
    font-size: 25px;
  }
  h1 {
    color: ${COLORS.light};
    font-size: 70px;
    animation: 3s ease-in ${fadeIn};
    padding-bottom: 20px;
  }
  a {
    text-decoration: none;
    background: ${COLORS.medium};
    animation: 3s ease-in ${fadeIn};
    padding: 20px 10px;
    margin-top: 30px;
    &:hover {
      transform: scale(1.2);
    }
  }
  span {
    color: ${COLORS.lightest};
    padding: 30px;
    font-size: 20px;
  }
`;

const HodlNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  padding: 200px 0 0 0;
  img {
    border-radius: 10px;
    border: 3px solid white;
    width: 650px;
    height: 400px;
  }
  p {
    color: ${COLORS.gray};
    animation: 3s ease-in ${fadeIn};
    font-size: 25px;
  }
  h1 {
    color: ${COLORS.light};
    font-size: 70px;
    animation: 3s ease-in ${fadeIn};
    padding-bottom: 20px;
  }
  a {
    text-decoration: none;
    background: ${COLORS.medium};
    animation: 3s ease-in ${fadeIn};
    padding: 20px 10px;
    margin-top: 30px;
    &:hover {
      transform: scale(1.2);
    }
  }
  span {
    color: ${COLORS.lightest};
    padding: 30px;
    font-size: 20px;
  }
`;

const Ecom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  padding: 200px 0 0 0;
  margin-bottom: 100px;
  img {
    border-radius: 10px;
    border: 3px solid white;
    width: 650px;
    height: 400px;
  }
  p {
    color: ${COLORS.gray};
    animation: 3s ease-in ${fadeIn};
    font-size: 25px;
  }
  h1 {
    color: ${COLORS.light};
    font-size: 70px;
    animation: 3s ease-in ${fadeIn};
    padding-bottom: 20px;
  }
  a {
    text-decoration: none;
    background: ${COLORS.medium};
    animation: 3s ease-in ${fadeIn};
    padding: 20px 10px;
    margin-top: 30px;
    &:hover {
      transform: scale(1.2);
    }
  }
  span {
    color: ${COLORS.lightest};
    padding: 30px;
    font-size: 20px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
`;

const Background = styled.div`
  background: url(${background}) center center / cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
`;

export default Projects;
