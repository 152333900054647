import { createGlobalStyle, keyframes } from "styled-components";

//colors

export const COLORS = {
  darkest: "#1a1a1a",
  dark: "#333333",
  medium: "#0b8cdb",
  light: "#6fc0f2",
  lightest: "#FCF6F1",
  gray: "#B4B4B4",
};

//default

export default createGlobalStyle`
   * {
    font-family: 'Rajdhani', sans-serif;
    line-height: 1.3;   
    margin: 0;
    padding: 0;
}

    html, body {
        margin: 0;
        padding: 0;
    }
   h1,h2,h3 {
    font-weight: lighter;
   }

   ol, ul {
       list-style: none;
   }

   a {
       text-decoration: none;
       transition: 0.4s ease-in-out;
   }
  `;

//Animations

export const fadeIn = keyframes`
0% {opacity: 0}
100% {opacity: 1}
`;

export const rotate = keyframes`
 0% {
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
  }
  2% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
  25% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
  27% {
    opacity: 0;
    transform: translate3d(0px, 50px, 0px);
  }
  99% {
    opacity: 0;
    transform: translate3d(0px, 50px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
  }
`;

export const rotateLast = keyframes`
 0% {
    opacity: 0;
    transform: translate3d(50px, 0px, 0px);
  }
  40% {
      opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
`;

export const boxRotate = keyframes`
0%{
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
16% {
    transform: rotateY(-90deg);
}
33%{
    transform: rotateY(-90deg) rotateZ(90deg);
}
50%{
    transform: rotateY(-180deg) rotateZ(90deg);
}
66%{
    transform: rotateY(-270deg) rotateX(90deg);
}
83%{
    transform:rotateX(90deg);
}

`;
